import logo from './assets/logo-gnnc.png';
import design from './assets/project-designer-rectjs-node-express-restapi-mongodb.png';
import manufacturing from './assets/project-java-electron-springboot-vuejs-database.png';
import './App.css';
import './resources/conn/Https';

const classes = {
  logo: {
    marginTop: 70,
    marginBottom: 30,
  },
  images: {
    maxWidth: '70%',
    // boxShadow: '2px 2px 5px rgb(50,50,50,0.9)',
    filter: 'drop-shadow(2px 2px 4px #444444CC)',
    imageRendering: 'high-quality',
  },
  h1: {
    fontSize: 50,
    fontWeight: 300,
    marginBottom: 0,
  },
  h3: {
    fontSize: 30,
    fontWeight: 300,
    marginTop: 10,
    marginBottom: 30,
  }
}

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} style={classes.logo} className="App-logo" alt="logo" />
        <h1 style={classes.h1}>Design Project</h1>
        <h3 style={classes.h3}>Rect.js + Node.js + Express.js + Rest API + Next.js + MongoDB</h3>
        <img src={design} alt="Design" style={classes.images} />
        {/* <h3 style={classes.h3}>Bizagi</h3> */}
        <h1 style={classes.h1}>Manufacturing Project</h1>
        <h3 style={classes.h3}>Electron + Vue.js + Java + Spring Boot + Rest API + Firebase</h3>
        <img src={manufacturing} alt="Design" style={classes.images} />
        <h1 style={classes.h1}>Bizagi Projects</h1>
        <h3 style={classes.h3}>Pools + Gantt</h3>
        <p>soon...</p>
      </header>
    </div>
  );
}

export default App;
